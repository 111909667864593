export const FaqsMeta = () => {
  return (
    <>
      <title>FAQs | BlackJet</title>
      <meta
        name='description'
        content="Discover how Black Jet’s monthly subscription puts personal aviation at your fingertips—all for a fixed price. Explore our FAQs to learn more"
      />
      <meta
        name='keywords'
        content="BlackJet, FAQs, membership, subscription, private Aviation, popular questions, safety & security, booking flights, in-flight experience, airport, what lies ahead"
      />
      <meta property='og:title' content='Black Jet | FAQs' />

      <meta
        property='og:description'
        content="Discover how Black Jet’s monthly subscription puts personal aviation at your fingertips—all for a fixed price. Explore our FAQs to learn more"
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://blackjet.au/faqs' />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content='Black Jet | FAQs' />
      <meta
        name='twitter:description'
        content="Discover how Black Jet’s monthly subscription puts personal aviation at your fingertips—all for a fixed price. Explore our FAQs to learn more"
      />

      <link rel='canonical' href='https://blackjet.au/faqs' />
    </>
  );
};

export const AboutUsMeta = () => {
  return (
    <>
      <title>About Us | BlackJet</title>
      <meta
        name='description'
        content="Visit our About Us page to learn how Black Jet, an Australian company, is transforming aviation with a personal, stress-free, and flexible approach"
        />
      <meta
        name='keywords'
        content="BlackJet, CASR, CASA, Part 135, Australian company, based in Sydney, Bankstown airport"
      />
      <meta name='author' content='Black Jet' />
      <meta name='robots' content='index, follow' />

      <meta property='og:title' content='Black Jet | About Us' />
      <meta
        property='og:description'
        content="Visit our About Us page to learn how Black Jet, an Australian company, is transforming aviation with a personal, stress-free, and flexible approach"
      />
      <meta property='og:url' content='https://blackjet.au/aboutus' />
      <meta property='og:type' content='website' />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content='Black Jet | About Us' />
      <meta
        name='twitter:description'
        content="Visit our About Us page to learn how Black Jet, an Australian company, is transforming aviation with a personal, stress-free, and flexible approach"
      />

      <link rel='canonical' href='https://blackjet.au/aboutus' />
    </>
  );
};

export const ContactUsMeta = () => {
  return (
    <>
      <title>Contact Us | BlackJet</title>
      <meta
        name='description'
        content="Get in touch with Black Jet’s team for assistance with memberships, the Black Jet app, or general inquiries"
      />
      <meta
        name='keywords'
        content="BlackJet, Contact Us, memberships, Black Jet App, general inquiries"
      />
      <meta property='og:title' content='Black Jet | Contact Us' />

      <meta
        property='og:description'
        content="Get in touch with Black Jet’s team for assistance with memberships, the Black Jet app, or general inquiries"
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://blackjet.au/contactus' />

      <link rel='canonical' href='https://blackjet.au/contactus' />
    </>
  );
};

export const LegalMeta = () => {
  return (
    <>
      <title>Legal | BlackJet</title>
      <meta
        name='description'
        content="Black Jet's Privacy Policy, Terms of Use, and more"
      />
      <meta
        name='keywords'
        content="BlackJet, Legal, Terms of Use, Privacy Policy"
      />
      <meta property='og:title' content='Black Jet | Legal' />

      <meta
        property='og:description'
        content="Black Jet's Privacy Policy, Terms of Use, and more"
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://blackjet.au/legal' />

      <link rel='canonical' href='https://blackjet.au/legal' />
    </>
  );
};

export const CareersMeta = () => {
  return (
    <>
      <title>Careers | BlackJet</title>
      <meta
        name='description'
        content="Join our team in shaping the future of aviation—more personal, stress-free, and flexible than ever"
      />
      <meta
        name='keywords'
        content="Black Jet, Careers, Team, Opportunitiesites, Availability, Availability, Availability"
      />
      <meta property='og:title' content='Careers | Black Jet' />

      <meta
        property='og:description'
        content="Join our team in shaping the future of aviation—more personal, stress-free, and flexible than ever"
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://blackjet.au/careers' />
      
      <link rel='canonical' href='https://blackjet.au/careers' />
    </>
  );
};


